import { Component, Vue, Model, Prop } from 'vue-property-decorator';
import { Report as ReportModel, Media } from '@/models/Report';
import Attachments, { AttachmentOptions } from '@/components/attachments/Attachments';
import Plattegronden from '@/views/Reports/Plattegronden/Plattegronden.vue';
import Aanzichtfotos from '@/views/Reports/Aanzichtfotos/Aanzichtfotos.vue';

@Component<Bijlagen>({
  components: {
    Plattegronden,
    Aanzichtfotos,
  },
})
export default class Bijlagen extends Vue {
  public $pageTitle = 'Bijlagen';

  @Model('report', { default: () => new ReportModel() })
  protected report!: ReportModel;

  @Prop({ default: true })
  protected allowEdit!: boolean;

  @Prop({ default: false })
  protected syncing!: boolean;

  protected isLoading = false;

  protected attachments: Media[] = [];

  protected selectedAttachment: Media | null = null;

  protected get attachmentOptions(): AttachmentOptions {
    return {
      model: this.report,
      type: 'attachments',
      showAddButton: true,
      showOrderButton: true,
      showTrillingsButton: true,
      headers: [
        {
          text: 'Bijlage',
          value: 'file_name',
        },
        {
          text: 'Beschrijving',
          value: 'description',
        },
        {
          text: 'Zichtbaar in het rapport',
          value: 'show_in_report',
        },
        {
          text: 'Geüpload door',
          value: 'uploaded_by',
        },
        {
          text: 'Aangemaakt op',
          value: 'created_at',
        },
        {
          text: 'Acties',
          value: 'actions',
          class: 'actions',
          visible: ! this.report.isClosed,
        },
      ],
    };
  }

  protected editAttachment(attachment: Media) {
    const attachementContainer = this.$refs.attachment as Attachments;
    if (! attachementContainer) {
      return;
    }
    attachementContainer.editAttachment(attachment);
  }

  protected deleteAttachment(attachment: Media) {
    const attachementContainer = this.$refs.attachment as Attachments;
    if (! attachementContainer) {
      return;
    }
    attachementContainer.deleteAttachment(attachment);
  }
}
