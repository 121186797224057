var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.report
      ? _c(
          "div",
          { staticClass: "elementPanel mb-3" },
          [
            _c(
              "v-layout",
              { attrs: { row: "", wrap: "" } },
              [
                _c(
                  "v-flex",
                  { attrs: { xs12: "" } },
                  [
                    _vm.isLoading
                      ? _c("LoaderCard", {
                          attrs: {
                            flat: "",
                            type: "spinner--center",
                            minHeight: "300px"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            ),
            !_vm.isLoading
              ? [
                  _vm.isLoading
                    ? _c("LoaderCard", {
                        attrs: { flat: "", type: "spinner--center" }
                      })
                    : _vm._e(),
                  !_vm.isLoading
                    ? _c("Attachments", {
                        ref: "attachment",
                        attrs: {
                          options: _vm.attachmentOptions,
                          report: _vm.report,
                          allowEdit: _vm.allowEdit
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item.file_name",
                              fn: function(item) {
                                return [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: item.original,
                                        target: "_blank"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("concatenateString")(
                                              item.file_name
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              }
                            },
                            {
                              key: "item.type",
                              fn: function(item) {
                                return [_vm._v(" " + _vm._s(item.type) + " ")]
                              }
                            },
                            {
                              key: "item.created_at",
                              fn: function(item) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("dateFormatTime")(
                                          item.created_at
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              }
                            },
                            {
                              key: "item.show_in_report",
                              fn: function(item) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.show_in_report == "0"
                                          ? "nee"
                                          : item.show_in_report == "1"
                                          ? "ja"
                                          : ""
                                      ) +
                                      " "
                                  )
                                ]
                              }
                            },
                            {
                              key: "item.actions",
                              fn: function(item) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "tw-flex tw-flex-row tw-flex-no-wrap tw-gap-8"
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "item__icon item__icon--action"
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: item.original,
                                                target: "_blank"
                                              }
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("cloud_download")
                                              ])
                                            ],
                                            1
                                          )
                                        ]
                                      ),
                                      _vm.allowEdit
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "item__icon item__icon--action"
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.editAttachment(
                                                        item
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v("edit")]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.allowEdit
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "item__icon item__icon--action delete"
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.deleteAttachment(
                                                        item
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v("delete")]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    : _vm._e()
                ]
              : _vm._e()
          ],
          2
        )
      : _vm._e(),
    _vm.report
      ? _c(
          "div",
          { staticClass: "elementPanel mb-3" },
          [
            _c("Plattegronden", {
              attrs: { report: _vm.report, isEditor: false }
            })
          ],
          1
        )
      : _vm._e(),
    _vm.report
      ? _c(
          "div",
          { staticClass: "elementPanel mb-3" },
          [
            _c("Aanzichtfotos", {
              attrs: { report: _vm.report, isEditor: false }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }